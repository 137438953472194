import React, { memo, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { DownloadIcon } from '@heroicons/react/solid';
import { useModal } from '../../hooks/useModal';
import Api from '../../api/Api';
import classNames from 'classnames';
import { useAuth } from '../../hooks/useAuth';
import ahoy from 'ahoy.js';
import Modal from '../molecules/modal/Modal';
import { FileCog } from 'lucide-react';

ahoy.configure({
  visitsUrl: '/steps/visits',
  eventsUrl: '/steps/events',
});

export const ExportPdfForm = ({ isOnMobile = false, msId }) => {
  const { sessionAuthHeader } = useAuth();
  const [part, setPart] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);

  const handleChangeSelectInput = (e) => {
    const value = e.target.value;
    setPart(parseInt(value));
  };

  const handleClickGeneratePdfBtn = () => {
    ahoy.track('$rapport_etude_pdf_export_par_email', {
      market_survey_id: msId,
    });

    const keys = [null, 'is_part1_only', 'is_part2_only'];

    const data = {
      market_survey_id: msId,
      ...(part > 0 ? { [keys[part]]: true } : {}),
    };

    Api.post('/market_survey/generate_pdf_report', data, {
      headers: {
        'X-USER-EMAIL': sessionAuthHeader?.email,
        'X-USER-TOKEN': sessionAuthHeader?.token,
      },
    }).then((res) => {
      if (res.status === 200) {
        setIsGenerating(true);
      }
    });
  };

  return (
    <div className="flex flex-col space-y-6">
      {!isOnMobile && (
        <div className="flex flex-col space-y-5">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <div className="text-center flex flex-col space-y-2">
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Export PDF
            </Dialog.Title>
            <p className="text-sm text-gray-500">
              Sélectionnez ci-dessous votre type de rapport
            </p>
          </div>
        </div>
      )}
      <div className="mt-4 flex flex-col space-y-6">
        <select
          onChange={handleChangeSelectInput}
          className="w-full p-3 rounded-md"
        >
          <option value={0}>Étude complète</option>
          <option value={1}>Analyse concurrentielle (Partie 1)</option>
          <option value={2}>Logements et Marché Immobilier (Partie 2)</option>
        </select>
        <button
          type="button"
          className={classNames(
            {
              'bg-blue-600 hover:bg-blue-700': !isGenerating,
              'bg-green-600': isGenerating,
            },
            'inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-4  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm'
          )}
          disabled={isGenerating}
          onClick={handleClickGeneratePdfBtn}
        >
          {!isGenerating ? (
            <>
              Générer le rapport <DownloadIcon className="ml-3 w-5 h-5" />{' '}
            </>
          ) : (
            <>
              Le rapport vous parviendra par email d'ici quelques minutes.
              Pensez à vérifier vos emails indésirables.
            </>
          )}
        </button>
      </div>
      <div className="flex justify-center">
        <div style={{ width: '70%' }} className="border-b-1 border-black" />
      </div>
      <a
        className="text-white flex justify-center w-full py-3 hover:bg-blue-700 bg-blue-800 rounded-md"
        href={`/export-pdf-module/${msId}`}
      >
        <span className="w-full flex items-center justify-center space-x-2">
          <span>Personnaliser votre rapport</span>
          <FileCog className="w-6 h-6" />
        </span>
      </a>
    </div>
  );
};

const ExportPDFModal = () => {
  const { msId, isExportPDFModalOpened, setIsExportPDFModalOpened } =
    useModal();

  return (
    <Modal
      onClose={() => setIsExportPDFModalOpened(false)}
      open={isExportPDFModalOpened}
    >
      <ExportPdfForm msId={msId} />
    </Modal>
  );
};

export default memo(ExportPDFModal);
