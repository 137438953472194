import { useEffect, useState } from 'react';

export const useMaxHeight = (isPdfRenderMode, justNavbar = false) => {
  const [maxHeight, setMaxHeight] = useState(300);

  useEffect(() => {
    const exec = () => {
      setMaxHeight(
        document.getElementById('navbar')?.clientHeight +
          document.getElementById('bottom-navbar')?.clientHeight +
          (justNavbar
            ? 0
            : document.getElementById('ms-page-header')?.clientHeight) +
          (isPdfRenderMode
            ? document.getElementById('first_section_banner')?.clientHeight
            : 0)
      );
    };
    exec();
    window.addEventListener('resize', exec);
    return () => {
      window.removeEventListener('resize', exec);
    };
  }, []);

  return maxHeight;
};

export const useMsContentMaxHeight = () => {
  const [maxHeight, setMaxHeight] = useState(300);

  useEffect(() => {
    const fixMaxHeight = () => {
      setMaxHeight(
        document.getElementById('navbar')?.clientHeight +
          document.getElementById('bottom-navbar')?.clientHeight +
          document.getElementById('ms-page-header')?.clientHeight +
          document.getElementById('first_section_banner')?.clientHeight
      );
    };
    fixMaxHeight();
    window.addEventListener('resize', fixMaxHeight);
    return () => {
      window.removeEventListener('resize', fixMaxHeight);
    };
  }, []);

  return maxHeight;
};
