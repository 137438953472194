import { useRef, useMemo } from 'react';
import {
  ANALYSE_CONCURRENTIELLE,
  COLLECTIVE_HOUSING,
  COMMUNE,
  // CONTACT,
  CSP,
  DEFINITION,
  DEF_AND_CONTACT,
  DEMOGRAPHY_AND_EMPLOYMENT,
  DETAILED_INFORMATION_2,
  DETAILED_INFORMATION_3,
  EDUCATION,
  FIELD_ATTRIBUTES,
  FROM_25_TO_39_YO,
  LOCAL_AUTHORITY_HOUSING,
  LOG_MARCHE_IMMO,
  MEDIAN_INCOME,
  MOBILITY,
  NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT,
  NEIGHBORHOOD,
  NET_RESERVATIONS_BY_EPCI_MUNICIPALITIES,
  NEW_BUYER_TYPE,
  NEW_IN_AREA_CITY_PRICE,
  NEW_ON_SALE,
  NEW_PROGRAMS,
  NEW_RESERVATION_BY_VAT_TYPE,
  OFFER_AVAILABLE,
  OLD_HOUSING_TREND,
  POLITICAL,
  PRICE_CHANGE,
  PRICE_EVOLUTION_2018,
  RENT_PRICE,
  RESERVATION,
  RESUME,
  SENIOR,
  TERRAIN_EMPLACEMENT_PARCELLES,
  TERRAIN_QUARTIER,
  UNEMPLOYMENT,
  VACANT_HOUSING,
} from '../_helpers/constant';
import { useDispatch } from 'react-redux';
import { getDataLogementSocial } from '../store/slices/marketSurvey';

export const setupNumberToSummary = (summaryBrut) =>
  summaryBrut.map((item, i) => ({
    ...item,
    summary: { ...item.summary, label: `${i + 1} - ${item.summary.label}` },
    content: item.content
      .filter((el) => el)
      .map((el, iC) => ({
        ...el,
        label: `${i + 1}.${iC + 1} - ${el.label}`,
      })),
  }));

const useSectionEDM = ({
  isCapemUser = false,
  isPart1Only = false,
  isPart2Only = false,
  methodes,
}) => {
  const dispatch = useDispatch();

  const marketSurveyRef = useRef(null);
  const resumeRef_1 = useRef(null);
  const resumeRef_2 = useRef(null);
  const resumeRef_3 = useRef(null);
  const resumeRef_4 = useRef(null);
  const resumeRef_5 = useRef(null);
  const reservation_ref = useRef(null);
  const price_change_ref = useRef(null);
  const offer_available_ref = useRef(null);
  const detailed_information_1_ref = useRef(null);
  const detailed_information_2_ref = useRef(null);
  const detailed_information_3_ref = useRef(null);
  const field_attributes_ref = useRef(null);
  const neighborhood_ref = useRef(null);
  const csp_ref = useRef(null);
  const from_25_to_39_yo_ref = useRef(null);
  const parcelles_emplacement_ref = useRef(null);
  const education_ref = useRef(null);
  const nearest_shops_and_public_equipment_ref = useRef(null);
  const new_programs_ref = useRef(null);
  const mobility_ref = useRef(null);
  const new_on_sale_ref = useRef(null);
  const new_in_area_city_price_ref = useRef(null);
  const new_buyer_type_ref = useRef(null);
  const vacant_housing_ref = useRef(null);
  const new_reservation_by_vat_type_ref = useRef(null);
  const net_reservations_by_epci_municipalities_ref = useRef(null);
  const price_evolution_2018_ref = useRef(null);
  const old_housing_trend_ref = useRef(null);
  const rent_price_ref = useRef(null);
  const local_authority_housing_ref = useRef(null);
  const collective_housing_ref = useRef(null);
  const political_ref = useRef(null);
  const demography_and_employment_ref = useRef(null);
  const median_income_ref = useRef(null);
  const age_ref = useRef(null);
  const unemployment_ref = useRef(null);
  const definitionsRef = useRef(null);
  const contactRef = useRef(null);

  const summaryBrut = useMemo(
    () => [
      {
        part: 1,
        name: ANALYSE_CONCURRENTIELLE,
        isHidden: isPart2Only,
        summary: {
          ref: marketSurveyRef,
          link: 'marketSurveyRef',
          label: 'Analyse concurentielle - votre sélection de programmes neufs',
        },
        content: [
          {
            // 1.1
            ref: resumeRef_1,
            link: 'resumeRef_1',
            label: 'Localisation, prix moyen, disponibilité',
            name: RESUME,
            getMethod: methodes.getResumeProgrammePart1,
          },
          {
            // 1.2
            ref: detailed_information_3_ref,
            link: 'detailed_information_3_ref',
            label: 'Offre disponible : par programme',
            name: DETAILED_INFORMATION_3,
            getMethod: methodes.getInfoDetailsSelectedProgrammesFunc,
          },
          {
            // 1.3
            ref: offer_available_ref,
            link: 'offer_available_ref',
            label: 'Offre disponible : par typologie',
            name: OFFER_AVAILABLE,
            getMethod: methodes.getOffersDispoDataPart1,
          },
          {
            // 1.4
            ref: price_change_ref,
            link: 'price_change_ref',
            label: 'Réservations : variation des prix et rythmes / taux moyens',
            name: PRICE_CHANGE,
            getMethod: methodes.getReservationsVariationPart1,
          },
          {
            // 1.5
            ref: reservation_ref,
            link: 'reservation_ref',
            label:
              'Réservations : nombre, surface, prix par type d’acheteurs et de logements',
            name: RESERVATION,
            getMethod: methodes.getReservationsPart1,
          },
          {
            // 1.6
            ref: detailed_information_2_ref,
            link: 'detailed_information_2_ref',
            label: 'Récapitulatif : par programme',
            name: DETAILED_INFORMATION_2,
            getMethod: methodes.getProgrammesSelected2_3Part1,
          },
        ],
      },
      {
        isHidden: isPart1Only,
        name: LOG_MARCHE_IMMO,
        part: 2,
        summary: {
          ref: resumeRef_2,
          link: 'resumeRef_2',
          label: 'Logements et Marché Immobilier',
        },
        content: [
          {
            // 2.1
            ref: new_programs_ref,
            link: 'new_programs_ref',
            label:
              'Neuf : Programmes en cours de commercialisation - prix moyen',
            name: NEW_PROGRAMS,
            getMethod: methodes.getProgrammesNeufsDataPart2,
          },
          {
            // 2.2
            ref: new_on_sale_ref,
            link: 'new_on_sale_ref',
            label:
              'Neuf : Mises en vente | Réservations | Offre disponible - Commune & EPCI',
            name: NEW_ON_SALE,
            getMethod: methodes.getNeufMevDataPart2,
          },
          {
            // 2.3
            ref: net_reservations_by_epci_municipalities_ref,
            link: 'net_reservations_by_epci_municipalities_ref',
            label:
              'Neuf : Mises en vente | Réservations nettes -  Communes limitrophes',
            name: NET_RESERVATIONS_BY_EPCI_MUNICIPALITIES,
            getMethod: methodes.getNeufMevLimotropheDataPart2,
          },
          {
            // 2.4
            ref: price_evolution_2018_ref,
            link: 'price_evolution_2018_ref',
            label: 'Neuf : Évolution des prix par type de TVA - Commune & EPCI',
            name: PRICE_EVOLUTION_2018,
            getMethod: methodes.getNeufPriceByTVADataPart2,
          },
          {
            // 2.5
            ref: new_in_area_city_price_ref,
            link: 'new_in_area_city_price_ref',
            label: 'Neuf : Évolution des prix - EPCI & Communes limitrophes',
            name: NEW_IN_AREA_CITY_PRICE,
            getMethod: methodes.getNeufMevLimotrophe12monthDataPart2,
          },
          {
            // 2.6
            ref: new_buyer_type_ref,
            link: 'new_buyer_type_ref',
            label:
              "Neuf : Réservations par type d'acquéreurs (Utilisateurs | Invest. | VB)",
            disabled: isCapemUser,
            name: NEW_BUYER_TYPE,
            getMethod: methodes.getNeufResParTydeAcquereurDataPart2,
          },
          {
            // 2.7
            ref: new_reservation_by_vat_type_ref,
            link: 'new_reservation_by_vat_type_ref',
            label:
              'Neuf : Réservations - Part Investisseur  | Part TVA Réduite',
            disabled: isCapemUser,
            name: NEW_RESERVATION_BY_VAT_TYPE,
            getMethod: methodes.getNeufResNetteDataPart2,
          },
          {
            // 2.8
            ref: old_housing_trend_ref,
            link: 'old_housing_trend_ref',
            label:
              'Marché Ancien : Appartements - Prix moyen | Nb. transactions - Commune & EPCI',
            name: OLD_HOUSING_TREND,
            getMethod: methodes.getAncienAppartementDataPart2,
          },
          {
            // 2.9
            ref: rent_price_ref,
            link: 'rent_price_ref',
            label:
              'Marché Locatif : Appartements - Prix moyen - Commune & EPCI',
            name: RENT_PRICE,
            getMethod: methodes.getMarcheLocatifAppartDataPart2,
          },
          {
            // 2.10
            ref: local_authority_housing_ref,
            link: 'local_authority_housing_ref',
            label: 'Logement Social : Parc - Évolutions et Demandes - Commune',
            name: LOCAL_AUTHORITY_HOUSING,
            getMethod: ({ fieldIds }) => {
              dispatch(getDataLogementSocial(fieldIds[0]));
            },
          },
          {
            // 2.11
            ref: collective_housing_ref,
            link: 'collective_housing_ref',
            label:
              'Logements : collectif - nombre et parts - EPCI & Communes limitrophes',
            name: COLLECTIVE_HOUSING,
            getMethod: methodes.getCollectifHousingDataPart2,
          },
          {
            // 2.12
            ref: vacant_housing_ref,
            link: 'vacant_housing_ref',
            label:
              'Logements : vacants - nombre et parts - EPCI & Communes limitrophes',
            name: VACANT_HOUSING,
            getMethod: methodes.getVacantHousingDataPart2,
          },
        ],
      },
      {
        name: TERRAIN_QUARTIER,
        isHidden: isPart1Only || isPart2Only,
        part: 3,
        summary: {
          ref: resumeRef_3,
          link: 'resumeRef_3',
          label: ' Le terrain et son quartier',
        },
        content: [
          {
            ref: parcelles_emplacement_ref,
            link: 'parcelles_emplacement_ref',
            label: 'Le terrain : emplacement & parcelles',
            name: TERRAIN_EMPLACEMENT_PARCELLES,
          },
          {
            ref: field_attributes_ref,
            link: 'field_attributes_ref',
            label: 'Terrain : caractéristique & risques',
            name: FIELD_ATTRIBUTES,
            getMethod: methodes.getAttributesTerrainDataPart3,
          },
          {
            ref: education_ref,
            link: 'education_ref',
            label: 'Ecoles et universités',
            name: EDUCATION,
            getMethod: methodes.getEducationDataPart3,
          },
          {
            ref: mobility_ref,
            link: 'mobility_ref',
            label: 'Transports & mobilité',
            name: MOBILITY,
            getMethod: methodes.getMobilityDataPart3,
          },
          {
            ref: nearest_shops_and_public_equipment_ref,
            link: 'nearest_shops_and_public_equipment_ref',
            label: 'Equipements public & services essentiels',
            name: NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT,
            getMethod: methodes.getNearestShopDataPart3,
          },
        ],
      },
      {
        name: COMMUNE,
        part: 4,
        isHidden: isPart1Only || isPart2Only,
        summary: {
          ref: resumeRef_4,
          link: 'resumeRef_4',
          label: 'La commune dans son territoire',
        },
        content: [
          {
            // 4.1
            ref: political_ref,
            link: 'political_ref',
            label: 'Une vision synoptique de la commune et de sa dynamique',
            name: POLITICAL,
            getMethod: methodes.getSynopticVisonAndDynamicCommuneData,
          },
          {
            // 4.2
            ref: demography_and_employment_ref,
            link: 'demography_and_employment_ref',
            label:
              'Démographie et emplois : Dynamique comparée des communes limitrophes',
            name: DEMOGRAPHY_AND_EMPLOYMENT,
            getMethod: methodes.getDemographieAndEmploiData,
          },
          {
            // 4.3
            ref: median_income_ref,
            link: 'median_income_ref',
            label: 'Revenus: Médiane et Variation par Unité de Consommation',
            name: MEDIAN_INCOME,
            getMethod: methodes.getRevenusMedianeVariationDataPart4,
          },
          {
            // 4.4
            ref: unemployment_ref,
            link: 'unemployment_ref',
            label: 'Chômage : Taux et variation',
            name: UNEMPLOYMENT,
            getMethod: methodes.getChomageTauxVariationDataPart4,
          },
          {
            // 4.5
            ref: neighborhood_ref,
            link: 'neighborhood_ref',
            label: 'Population : Nombre et variation',
            name: NEIGHBORHOOD,
            getMethod: methodes.getPopulationNombreVariationDataPart4,
          },
          {
            // 4.6
            ref: csp_ref,
            link: 'csp_ref',
            label: 'Population : Focus - Cadres',
            name: CSP,
            getMethod: methodes.getPopulationFocusCadreDataPart4,
          },
          {
            // 4.7
            ref: from_25_to_39_yo_ref,
            link: 'from_25_to_39_yo_ref',
            label: 'Population : Focus - Personnes de 25 à 39 ans',
            name: FROM_25_TO_39_YO,
            getMethod: methodes.getpopulationFocus25To39DataPart4,
          },
          {
            // 4.8
            ref: age_ref,
            link: 'age_ref',
            label: 'Population : Focus - Seniors ( + de 65 ans)',
            name: SENIOR,
            getMethod: methodes.getpopulationSeniorDataPart4,
          },
        ],
      },
      {
        name: DEF_AND_CONTACT,
        part: 5,
        summary: {
          ref: definitionsRef,
          link: 'definitionsRef',
          label: 'Sources et définitions',
        },
        noContent: true,
        content: [
          {
            ref: definitionsRef,
            link: 'definitionsRef',
            label: 'Sources et définitions',
            name: DEFINITION,
            getMethod: methodes.getDefinition,
          },
        ],
      },
    ],
    [isPart1Only, isPart2Only]
  );

  const summary = useMemo(
    () =>
      setupNumberToSummary(summaryBrut).map((item) => ({
        ...item,
        content: item.noContent ? [] : item.content,
      })),
    [summaryBrut]
  );

  const defaultTemplate = useMemo(() => {
    return summaryBrut.map((el1, i1) => ({
      name: el1.name,
      order: i1 + 1,
      content: el1.content.map((el2, i2) => ({
        name: el2.name,
        order: i2 + 1,
        checked: true,
      })),
    }));
  }, [summaryBrut]);

  return {
    summary,
    summaryBrut,
    defaultTemplate,
    refs: {
      marketSurveyRef,
      resumeRef_1,
      resumeRef_2,
      resumeRef_3,
      resumeRef_4,
      resumeRef_5,
      reservation_ref,
      parcelles_emplacement_ref,
      price_change_ref,
      offer_available_ref,
      detailed_information_1_ref,
      detailed_information_2_ref,
      detailed_information_3_ref,
      field_attributes_ref,
      neighborhood_ref,
      csp_ref,
      from_25_to_39_yo_ref,
      education_ref,
      nearest_shops_and_public_equipment_ref,
      new_programs_ref,
      mobility_ref,
      new_on_sale_ref,
      new_in_area_city_price_ref,
      new_buyer_type_ref,
      vacant_housing_ref,
      new_reservation_by_vat_type_ref,
      net_reservations_by_epci_municipalities_ref,
      price_evolution_2018_ref,
      old_housing_trend_ref,
      rent_price_ref,
      local_authority_housing_ref,
      collective_housing_ref,
      political_ref,
      demography_and_employment_ref,
      median_income_ref,
      age_ref,
      unemployment_ref,
      definitionsRef,
      contactRef,
    },
  };
};

export default useSectionEDM;
