import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import CoverMarketSurveySection from './MarketSurveys/sections/CoverMarketSurveySection';
import { useParams } from 'react-router-dom';
import MarketSurveyPageHeader from './MarketSurveys/sections/MarketSurveyPageHeader';
import MarketSurveySummary from './MarketSurveys/sections/MarketSurveySummary';
import { useAuth } from '../hooks/useAuth';
import _, { omit } from 'lodash';
import MSTableSection from './MarketSurveys/sections/MSTableSection';
import BannerSection from './MarketSurveys/sections/BannerSection';
import {
  BLUE_THEME,
  COLLECTIVE_HOUSING,
  CSP,
  DARK_BLUE_THEME,
  DETAILED_INFORMATION_2,
  DETAILED_INFORMATION_3,
  EDUCATION,
  FIELD_ATTRIBUTES,
  FROM_25_TO_39_YO,
  LOCAL_AUTHORITY_HOUSING,
  MOBILITY,
  NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT,
  NEIGHBORHOOD,
  NET_RESERVATIONS_BY_EPCI_MUNICIPALITIES,
  NEW_BUYER_TYPE,
  NEW_IN_AREA_CITY_PRICE,
  NEW_ON_SALE,
  NEW_PROGRAMS,
  NEW_RESERVATION_BY_VAT_TYPE,
  OFFER_AVAILABLE,
  OLD_HOUSING_TREND,
  PRICE_CHANGE,
  PRICE_EVOLUTION_2018,
  RENT_PRICE,
  RESERVATION,
  VACANT_HOUSING,
  GREEN_THEME,
  GRAY_THEME,
  UNEMPLOYMENT,
  MEDIAN_INCOME,
  DEMOGRAPHY_AND_EMPLOYMENT,
  POLITICAL,
  MERGED_ROW_TABLE,
  RESUME,
  SENIOR,
  TERRAIN_EMPLACEMENT_PARCELLES,
  COLLECTIVE_HOUSING_MAP_1,
  COLLECTIVE_HOUSING_MAP_2,
  NB_LOG_VACANT_MAP,
  EVO_2014_2020_MAP,
  CHOMAGE_VARIATION_DIFF_MAP,
  CHOMAGE_VARIATION_TAUX_MAP,
  REVENU_MEDIAN_ANNUEL_MAP,
  REVENU_VARIATION_2019_2020,
  POPULATION_NB_MAP,
  POPULATION_VARIATION_MAP,
  POPULATION_CADRE_NB_MAP,
  POPULATION_CADRE_EVO_MAP,
  FROM_25_TO_39_YO_NB_MAP,
  FROM_25_TO_39_YO_VARIATION_MAP,
  SENIOR_NB_MAP,
  SENIOR_VARIATION_MAP,
  DEFINITION,
  CONTACT,
} from '../_helpers/constant';
import ContactMarketSurveySection from './MarketSurveys/sections/ContactMarketSurveySection';
import { useDispatch } from 'react-redux';
import { marketSurveyContext } from '../providers/marketSurveyProvider';
import LogementSocial from './MarketSurveys/sections/LogementSocial';
import {
  MarketSurveySlice,
  updateMarketSurveyMeta,
} from '../store/slices/marketSurvey';
import {
  COLOR_EPCI_COMMUNE,
  COLOR_NEUF_VENTILATION,
} from '../constants/marketSurvey.const';
import { useEDM, useEDMStore } from '../_helpers/useGetDataEdm';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { usePrograms } from '../hooks/usePrograms';
import TerrainEmplacementParcelles from './MarketSurveys/sections/TerrainEmplacementParcelles';
import ProgramsBlockWithMap from './MarketSurveys/sections/ProgramsBlockWithMap';
import SynopticVisionAndDynamicCommune from './MarketSurveys/sections/SynopticVisionAndDynamicCommune';
import { useMetadataMs } from '../hooks/useChangeOnMSMap';
import MarketSurveyMobile from './MarketSurveys/sections/MarketSurveyMobile';
import { useLocation } from 'react-router-dom';

export const select_1_4_options = [
  {
    value: 3,
    label: '3 derniers mois',
  },
  {
    value: 6,
    label: '6 derniers mois',
  },
  {
    value: 12,
    label: '12 derniers mois',
    default: true,
  },
];

export const TitleWithSelect = ({
  isPdf = false,
  onChangeSelect,
  value,
  name,
  title: titleSection,
  options,
  placeholder,
  partName,
  nameSection,
}) => {
  const [open, setOpen] = useState(false);
  const [valueState, setValue] = useState(null);
  const currentSummary = useEDMStore((state) => state.currentSummary);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const partContent =
    partName && currentSummary.find((item) => item.name === partName)?.content;

  const title =
    titleSection ||
    partContent?.find((item) => item.name === nameSection)?.label;

  return (
    <span>
      <span>{title}</span>
      {isPdf && <span>&nbsp;({value?.label})</span>}
      {!isPdf && (
        <span
          onClick={() => setOpen(!open)}
          className="ml-4 bg-green-700 bg-opacity-25 inline-block cursor-pointer px-6 py-2 border-1 border-gray-500 rounded-md text-base relative"
        >
          <span className="flex items-center gap-x-4">
            <span>{valueState?.label || placeholder}</span>
            <span>
              {open ? (
                <ChevronUpIcon className="w-6 h-6" />
              ) : (
                <ChevronDownIcon className="w-6 h-6" />
              )}
            </span>
          </span>
          {open && (
            <span
              style={{ top: 42 }}
              className="flex flex-col absolute w-full left-0 z-50 bg-white shadow-md"
            >
              {options.map((value, index) => (
                <span
                  key={index}
                  className={classNames(
                    'block px-4 py-2 border-b-1 whitespace-nowrap normal-case text-xs font-normal  hover:bg-green-700 hover:text-white',
                    {
                      'bg-green-700 bg-opacity-25':
                        value?.value === valueState?.value,
                    }
                  )}
                  onClick={() => {
                    setValue(value);
                    onChangeSelect &&
                      onChangeSelect({
                        target: { value: value?.value ? value : null, name },
                      });
                  }}
                >
                  {value.value ? (
                    value.label
                  ) : (
                    <span className="opacity-50">...</span>
                  )}
                </span>
              ))}
            </span>
          )}
        </span>
      )}
    </span>
  );
};

const MarketSurveyPage = ({ isShare = false }) => {
  const { isCapemUser: isCapemUserFromUseAuth, user } = useAuth();

  const location = useLocation();

  const isCapemUser = useMemo(() => {
    if (isShare) {
      const queryParams = new URLSearchParams(location.search);
      return queryParams.get('icu') === 'true';
    }
    return isCapemUserFromUseAuth;
  }, [isShare, isCapemUserFromUseAuth.location]);

  const { survey_id, token_survey } = useParams();
  const { activePrograms } = usePrograms();
  const { setIsShare, setSurveyId } = useMetadataMs((state) => state);

  const {
    states: { fieldIds, msMetaData, dateMs, setMsMetaData },
    data: {
      data: {
        parcelData,
        resumeProgrammePart1,
        offersDispoDataPart1,
        reservationsPart1,
        reservationsVariationDataPart1,
        programmesSelected2_3Part1,
        programmesNeufDataPart2,
        neufMevDataPart2,
        neufMevLimotropheDataPart2,
        neufPriceByTVADataPart2,
        neufMevLimotrophe12monthDataPart2,
        neufResParTydeAcquereurDataPart2,
        neufResNetteDataPart2,
        ancienAppartementDataPart2,
        marcheLocatifAppartDataPart2,
        collectifHousingDataPart2,
        vacantHousingDataPart2,
        attributesTerrainDataPart3,
        educationDataPart3,
        mobilityDataPart3,
        nearestShopDataPart3,
        synopticVisonAndDynamicCommuneData,
        demographieAndEmploiData,
        revenusMedianeVariationDataPart4,
        chomageTauxVariationDataPart4,
        populationNombreVariationDataPart4,
        populationFocusCadreDataPart4,
        populationFocus25To39DataPart4,
        populationSeniorDataPart4,
        definition,
      },
      infosDetailsSelectedProgrammes,
    },
    methodes: { getReservationsVariationPart1, handleUpdateMSName },
    summary,
    refs: {
      marketSurveyRef,
      resumeRef_1,
      resumeRef_2,
      resumeRef_3,
      resumeRef_4,
      resumeRef_5,
      reservation_ref,
      price_change_ref,
      offer_available_ref,
      parcelles_emplacement_ref,
      detailed_information_2_ref,
      detailed_information_3_ref,
      field_attributes_ref,
      neighborhood_ref,
      csp_ref,
      from_25_to_39_yo_ref,
      education_ref,
      nearest_shops_and_public_equipment_ref,
      new_programs_ref,
      mobility_ref,
      new_on_sale_ref,
      new_in_area_city_price_ref,
      new_buyer_type_ref,
      vacant_housing_ref,
      new_reservation_by_vat_type_ref,
      net_reservations_by_epci_municipalities_ref,
      price_evolution_2018_ref,
      old_housing_trend_ref,
      rent_price_ref,
      local_authority_housing_ref,
      collective_housing_ref,
      political_ref,
      demography_and_employment_ref,
      median_income_ref,
      age_ref,
      unemployment_ref,
      definitionsRef,
      contactRef,
    },
  } = useEDM(isShare ? token_survey : survey_id, {
    isCapemUser: user ? isCapemUser : true,
    isShare,
  });

  const dispatch = useDispatch();

  const { setDownloadLinksDocs, setIsShareStudy } =
    useContext(marketSurveyContext);

  useEffect(() => {
    setDownloadLinksDocs(null);
    window.scroll({ top: 0 });
    setIsShareStudy(isShare);
    var _pageYoffset = 0;
    var first = false;
    setIsShare(isShare);
    setSurveyId(survey_id);
    window.addEventListener('fullscreenchange', () => {
      const valueheightTop = (window.innerHeight * 20) / 100;
      if (document.fullscreenElement) {
        _pageYoffset = window.pageYOffset + (!first ? valueheightTop : 0);
      } else {
        setTimeout(() => {
          window.scroll({
            top: _pageYoffset,
          });
          first = true;
        }, 100);
      }
    });
  }, []);

  useLayoutEffect(() => {
    dispatch(MarketSurveySlice.actions.setShowedMsgErrorIsochrone(false));
  }, [location?.pathname]);

  const saveChangeMsData = (metadata) => {
    if (!isShare) {
      dispatch(updateMarketSurveyMeta({ survey_id, meta: metadata }));
    }
  };

  const handleChangeMsDataMap = useCallback(
    (value, name) => {
      const currentData = { ...msMetaData, [name]: value };
      setMsMetaData(currentData);
      saveChangeMsData(currentData);
    },
    [msMetaData]
  );

  const handleChangeTitleSelect = useCallback(
    (e) => {
      const currentValue = e.target.value;
      if (e.target.value) {
        const currentData = {
          ...msMetaData,
          [e.target.name]: e.target.value,
        };
        setMsMetaData(currentData);
        saveChangeMsData(currentData);
      } else {
        const currentData = omit(msMetaData, e.target.name);
        setMsMetaData(currentData);
        saveChangeMsData(currentData);
      }
      getReservationsVariationPart1({
        activePrograms,
        periode: currentValue?.value || null,
      });
    },
    [activePrograms, msMetaData]
  );

  if (!fieldIds.length) {
    return <></>;
  }

  return (
    <>
      <div className="hidden lg:block">
        <MarketSurveyPageHeader
          tokenSurvey={token_survey}
          dateMs={dateMs}
          refs={summary}
          surveyId={survey_id}
          handleUpdateMSName={handleUpdateMSName}
          marketSurvey={msMetaData}
          isShare={isShare}
        />
        <div className="overflow-clip z-10 relative">
          <CoverMarketSurveySection
            surveyId={survey_id}
            dateMs={dateMs}
            parcelData={parcelData}
            onChangeZonageFlux={handleChangeMsDataMap}
            msMetaData={msMetaData}
          />

          {/* PART 1 */}
          <BannerSection
            title="1 - Analyse concurrentielle - votre sélection de programmes neufs"
            surveyID={survey_id}
            theme={GREEN_THEME}
          />
          <MarketSurveySummary
            isPart1={true}
            ref={marketSurveyRef}
            theme={GREEN_THEME}
            refsArray={summary}
          />
          <MSTableSection
            ref={resumeRef_1}
            title="1.1 – LOCALISATION, PRIX MOYEN, DISPONIBILITÉ"
            surveyId={survey_id}
            commentFieldName={RESUME}
            personalizedContent={
              <ProgramsBlockWithMap
                isPdfMode={false}
                contentData={resumeProgrammePart1}
              />
            }
            part={1}
          />
          {infosDetailsSelectedProgrammes && (
            <MSTableSection
              ref={detailed_information_3_ref}
              title="1.2 – OFFRE DISPONIBLE : PAR PROGRAMME"
              surveyId={survey_id}
              data={[
                {
                  type: MERGED_ROW_TABLE,
                },
              ]}
              commentFieldName={DETAILED_INFORMATION_3}
              theme={GREEN_THEME}
              infosDetailsSelectedProgrammes={infosDetailsSelectedProgrammes}
              part={1}
            />
          )}
          <MSTableSection
            ref={offer_available_ref}
            title="1.3 – OFFRE DISPONIBLE : PAR TYPOLOGIE"
            data={offersDispoDataPart1}
            commentFieldName={OFFER_AVAILABLE}
            theme={GREEN_THEME}
            strippedTable
            surveyId={survey_id}
            part={1}
          />
          <MSTableSection
            ref={price_change_ref}
            title={
              <TitleWithSelect
                title="1.4 – RÉSERVATIONS : VARIATION DES PRIX ET RYTHMES / TAUX MOYENS"
                options={select_1_4_options}
                placeholder="Période"
                onChangeSelect={handleChangeTitleSelect}
                name="periode_1_4"
                value={msMetaData?.periode_1_4 || select_1_4_options[2]}
              />
            }
            data={reservationsVariationDataPart1}
            strippedTable
            commentFieldName={PRICE_CHANGE}
            theme={GREEN_THEME}
            surveyId={survey_id}
            part={1}
          />
          <MSTableSection
            ref={reservation_ref}
            title="1.5 – RÉSERVATIONS : NOMBRE, SURFACE, PRIX PAR TYPE D’ACHETEURS ET DE LOGEMENTS"
            data={reservationsPart1}
            commentFieldName={RESERVATION}
            theme={GREEN_THEME}
            surveyId={survey_id}
            part={1}
          />
          <MSTableSection
            ref={detailed_information_2_ref}
            title="1.6 – RÉCAPITULATIF : PAR PROGRAMME"
            data={programmesSelected2_3Part1}
            commentFieldName={DETAILED_INFORMATION_2}
            theme={GREEN_THEME}
            strippedTable
            surveyId={survey_id}
            part={1}
          />
          {/* PART 1 END */}

          {/* PART 2 */}
          <BannerSection
            title="2 – Logements et Marché Immobilier"
            surveyID={survey_id}
            theme={DARK_BLUE_THEME}
          />
          <MarketSurveySummary
            refsArray={summary}
            ref={resumeRef_2}
            isPart2={true}
            theme={DARK_BLUE_THEME}
          />
          <MSTableSection
            ref={new_programs_ref}
            title="2.1 – NEUF : PROGRAMMES EN COURS DE COMMERCIALISATION - PRIX MOYEN"
            data={programmesNeufDataPart2}
            surveyId={survey_id}
            commentFieldName={NEW_PROGRAMS}
            part={2}
          />
          <MSTableSection
            ref={new_on_sale_ref}
            title="2.2 – NEUF : MISES EN VENTE | RÉSERVATIONS | OFFRE DISPONIBLE - COMMUNE & EPCI (SUR 5 ANS)"
            surveyId={survey_id}
            data={neufMevDataPart2}
            theme={DARK_BLUE_THEME}
            commentFieldName={NEW_ON_SALE}
            coloredTrOptions={COLOR_EPCI_COMMUNE}
            separatedTr={3}
            part={2}
          />
          <MSTableSection
            ref={net_reservations_by_epci_municipalities_ref}
            title="2.3 – NEUF : MISES EN VENTE | RÉSERVATIONS NETTES | COMMUNES LIMITROPHES (SUR 5 ANS)"
            surveyId={survey_id}
            data={neufMevLimotropheDataPart2}
            theme={DARK_BLUE_THEME}
            commentFieldName={NET_RESERVATIONS_BY_EPCI_MUNICIPALITIES}
            strippedTable
            part={2}
          />
          <MSTableSection
            ref={price_evolution_2018_ref}
            title="2.4 – NEUF : ÉVOLUTION DES PRIX PAR TYPE DE TVA - COMMUNES & EPCI (SUR 5 ANS)"
            surveyId={survey_id}
            data={neufPriceByTVADataPart2}
            theme={DARK_BLUE_THEME}
            commentFieldName={PRICE_EVOLUTION_2018}
            coloredTrOptions={COLOR_EPCI_COMMUNE}
            separatedTr={3}
            part={2}
          />
          <MSTableSection
            ref={new_in_area_city_price_ref}
            title="2.5 – NEUF : ÉVOLUTION DES PRIX - EPCI & COMMUNES LIMITROPHES (SUR 12 MOIS)"
            surveyId={survey_id}
            data={neufMevLimotrophe12monthDataPart2}
            theme={DARK_BLUE_THEME}
            commentFieldName={NEW_IN_AREA_CITY_PRICE}
            strippedTable
            part={2}
          />
          {!isCapemUser && (
            <MSTableSection
              ref={new_buyer_type_ref}
              title="2.6 – NEUF : RÉSERVATIONS PAR TYPE D'ACQUÉREURS (UTILISATEURS | INVEST. | VB)"
              surveyId={survey_id}
              data={neufResParTydeAcquereurDataPart2}
              theme={DARK_BLUE_THEME}
              commentFieldName={NEW_BUYER_TYPE}
              coloredTrOptions={COLOR_NEUF_VENTILATION}
              part={2}
            />
          )}
          {!isCapemUser && (
            <MSTableSection
              ref={new_reservation_by_vat_type_ref}
              title="2.7 – NEUF : RÉSERVATIONS NETTES | % INVESTISSEURS ET % TVA RÉDUITE"
              surveyId={survey_id}
              data={neufResNetteDataPart2}
              theme={DARK_BLUE_THEME}
              commentFieldName={NEW_RESERVATION_BY_VAT_TYPE}
              coloredTrOptions={COLOR_EPCI_COMMUNE}
              separatedTr={3}
              part={2}
            />
          )}
          <MSTableSection
            ref={old_housing_trend_ref}
            title="2.8 – MARCHÉ ANCIEN : APPARTEMENTS - PRIX MOYEN | NB. TRANSACTIONS - COMMUNE & EPCI (SUR 5 ANS)"
            surveyId={survey_id}
            data={ancienAppartementDataPart2}
            theme={DARK_BLUE_THEME}
            commentFieldName={OLD_HOUSING_TREND}
            coloredTrOptions={COLOR_EPCI_COMMUNE}
            separatedTr={3}
            part={2}
          />
          <MSTableSection
            ref={rent_price_ref}
            title="2.9 – MARCHÉ LOCATIF : APPARTEMENTS - PRIX MOYEN - COMMUNE & EPCI"
            surveyId={survey_id}
            data={marcheLocatifAppartDataPart2}
            theme={DARK_BLUE_THEME}
            commentFieldName={RENT_PRICE}
            part={2}
          />
          <MSTableSection
            ref={local_authority_housing_ref}
            title="2.10 – LOGEMENT SOCIAL : PARC - ÉVOLUTIONS ET DEMANDES - COMMUNE"
            surveyId={survey_id}
            theme={DARK_BLUE_THEME}
            commentFieldName={LOCAL_AUTHORITY_HOUSING}
            personalizedContent={<LogementSocial theme={DARK_BLUE_THEME} />}
            part={2}
          />
          <MSTableSection
            ref={collective_housing_ref}
            title="2.11 – LOGEMENTS : COLLECTIFS - NOMBRE ET PARTS - EPCI & COMMUNES LIMITROPHES"
            surveyId={survey_id}
            data={collectifHousingDataPart2}
            theme={DARK_BLUE_THEME}
            switchModeMapData={{
              labels: [
                'Nombre de logements collectifs (appartements) en 2021',
                'Evolution 2015 - 2021',
              ],
              namesMap: [COLLECTIVE_HOUSING_MAP_1, COLLECTIVE_HOUSING_MAP_2],
              name: COLLECTIVE_HOUSING,
              currentMap: msMetaData ? msMetaData[COLLECTIVE_HOUSING] : 'first',
              onChangeMsDataMap: handleChangeMsDataMap,
            }}
            commentFieldName={COLLECTIVE_HOUSING}
            part={2}
          />
          <MSTableSection
            ref={vacant_housing_ref}
            title="2.12 – LOGEMENTS : VACANTS - NOMBRE ET PARTS - EPCI & COMMUNES LIMITROPHES"
            surveyId={survey_id}
            data={vacantHousingDataPart2}
            theme={DARK_BLUE_THEME}
            switchModeMapData={{
              labels: [
                'Nombre de logements vacants en 2021',
                'Evolution 2015 - 2021',
              ],
              name: VACANT_HOUSING,
              namesMap: [NB_LOG_VACANT_MAP, EVO_2014_2020_MAP],
              currentMap: msMetaData ? msMetaData[VACANT_HOUSING] : 'first',
              onChangeMsDataMap: handleChangeMsDataMap,
            }}
            commentFieldName={VACANT_HOUSING}
            part={2}
          />
          {/* PART 2 END */}

          {/* PART 3 */}
          <BannerSection
            title="3-Le terrain et son quartier"
            surveyID={survey_id}
            theme={BLUE_THEME}
          />
          <MarketSurveySummary
            refsArray={summary}
            isPart3={true}
            ref={resumeRef_3}
          />
          <MSTableSection
            ref={parcelles_emplacement_ref}
            title="3.1 - LE TERRAIN : EMPLACEMENT & PARCELLES"
            surveyId={survey_id}
            theme={BLUE_THEME}
            commentFieldName={TERRAIN_EMPLACEMENT_PARCELLES}
            personalizedContent={
              <TerrainEmplacementParcelles
                parcelData={parcelData}
                isPdfMode={false}
              />
            }
            part={3}
          />
          <MSTableSection
            ref={field_attributes_ref}
            title="3.2 – LE TERRAIN : CARACTERISTIQUES & RISQUES"
            surveyId={survey_id}
            data={attributesTerrainDataPart3}
            commentFieldName={FIELD_ATTRIBUTES}
            theme={BLUE_THEME}
            part={3}
          />
          <MSTableSection
            ref={education_ref}
            title="3.3 – ECOLES & UNIVERSITES"
            surveyId={survey_id}
            data={educationDataPart3}
            commentFieldName={EDUCATION}
            theme={BLUE_THEME}
            needIsochrone
            eductionMap
            part={3}
          />
          <MSTableSection
            ref={mobility_ref}
            title="3.4 – TRANSPORTS & MOBILITE"
            surveyId={survey_id}
            data={mobilityDataPart3}
            commentFieldName={MOBILITY}
            theme={BLUE_THEME}
            needIsochrone
            part={3}
          />
          <MSTableSection
            ref={nearest_shops_and_public_equipment_ref}
            title="3.5 – EQUIPEMENTS PUBLICS & SERVICES ESSENTIELS"
            surveyId={survey_id}
            data={nearestShopDataPart3}
            commentFieldName={NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT}
            theme={BLUE_THEME}
            needIsochrone
            part={3}
          />
          {/* PART 3 END */}

          {/* PART 4 */}
          <BannerSection
            title="4- La commune dans son territoire"
            surveyID={survey_id}
            theme={GRAY_THEME}
          />
          <MarketSurveySummary
            refsArray={summary}
            isPart4={true}
            ref={resumeRef_4}
            theme={GRAY_THEME}
          />
          <MSTableSection
            ref={political_ref}
            title="4.1 – UNE VISION SYNOPTIQUE DE LA COMMUNE ET DE SA DYNAMIQUE"
            surveyId={survey_id}
            theme={DARK_BLUE_THEME}
            commentFieldName={POLITICAL}
            personalizedContent={
              <SynopticVisionAndDynamicCommune
                data={synopticVisonAndDynamicCommuneData}
              />
            }
            part={4}
          />
          <MSTableSection
            ref={demography_and_employment_ref}
            title="4.2 – DÉMOGRAPHIE ET EMPLOI : DYNAMIQUE COMPARÉE DES COMMUNES LIMITROPHES"
            surveyId={survey_id}
            data={demographieAndEmploiData}
            theme={GRAY_THEME}
            commentFieldName={DEMOGRAPHY_AND_EMPLOYMENT}
            personnalisedGrid={{ containerClass: 'grid grid-cols-12 gap-y-10' }}
            part={4}
          />
          <MSTableSection
            ref={median_income_ref}
            title="4.3 – REVENUS : MÉDIANE ET VARIATION PAR UNITÉ DE CONSOMMATION"
            surveyId={survey_id}
            data={revenusMedianeVariationDataPart4}
            theme={GRAY_THEME}
            switchModeMapData={{
              labels: [
                'Revenu médian annuel disponible en 2021 (Insee 2024)',
                'Variation 2020-2021',
              ],
              namesMap: [REVENU_MEDIAN_ANNUEL_MAP, REVENU_VARIATION_2019_2020],
              name: MEDIAN_INCOME,
              currentMap: msMetaData ? msMetaData[MEDIAN_INCOME] : 'first',
              onChangeMsDataMap: handleChangeMsDataMap,
            }}
            commentFieldName={MEDIAN_INCOME}
            part={4}
          />
          <MSTableSection
            ref={unemployment_ref}
            title="4.4 – CHÔMAGE : TAUX ET VARIATION"
            surveyId={survey_id}
            data={chomageTauxVariationDataPart4}
            theme={GRAY_THEME}
            switchModeMapData={{
              labels: [
                'Taux de chômage 2021 (Insee 2024)',
                'Différence 2021-2015 en points',
              ],
              name: UNEMPLOYMENT,
              namesMap: [
                CHOMAGE_VARIATION_TAUX_MAP,
                CHOMAGE_VARIATION_DIFF_MAP,
              ],
              currentMap: msMetaData ? msMetaData[UNEMPLOYMENT] : 'first',
              onChangeMsDataMap: handleChangeMsDataMap,
            }}
            commentFieldName={UNEMPLOYMENT}
            part={4}
          />
          <MSTableSection
            ref={neighborhood_ref}
            title="4.5 – POPULATION : NOMBRE ET VARIATION"
            surveyId={survey_id}
            data={populationNombreVariationDataPart4}
            theme={GRAY_THEME}
            switchModeMapData={{
              labels: [
                'Population en 2021 (IGN/Insee 2024)',
                'Variation 2019 - 2021',
              ],
              name: NEIGHBORHOOD,
              namesMap: [POPULATION_NB_MAP, POPULATION_VARIATION_MAP],
              currentMap: msMetaData ? msMetaData[NEIGHBORHOOD] : 'first',
              onChangeMsDataMap: handleChangeMsDataMap,
            }}
            commentFieldName={NEIGHBORHOOD}
            part={4}
          />
          <MSTableSection
            ref={csp_ref}
            title="4.6 – POPULATION : FOCUS - CADRES"
            surveyId={survey_id}
            data={populationFocusCadreDataPart4}
            theme={GRAY_THEME}
            commentFieldName={CSP}
            switchModeMapData={{
              labels: [
                'Part des cadres dans la population en 2021 (Insee 2024)',
                'Variation 2015 - 2021',
              ],
              name: CSP,
              currentMap: msMetaData ? msMetaData[CSP] : 'first',
              namesMap: [POPULATION_CADRE_NB_MAP, POPULATION_CADRE_EVO_MAP],
              onChangeMsDataMap: handleChangeMsDataMap,
            }}
            part={4}
          />
          <MSTableSection
            ref={from_25_to_39_yo_ref}
            title="4.7 – POPULATION : FOCUS - PERSONNES DE 25 À 39 ANS"
            surveyId={survey_id}
            data={populationFocus25To39DataPart4}
            theme={GRAY_THEME}
            commentFieldName={FROM_25_TO_39_YO}
            switchModeMapData={{
              labels: [
                'Part des 25-39 ans dans la population en 2021 (Insee 2024)',
                'Variation 2015 - 2021',
              ],
              name: FROM_25_TO_39_YO,
              namesMap: [
                FROM_25_TO_39_YO_NB_MAP,
                FROM_25_TO_39_YO_VARIATION_MAP,
              ],
              currentMap: msMetaData ? msMetaData[FROM_25_TO_39_YO] : 'first',
              onChangeMsDataMap: handleChangeMsDataMap,
            }}
            part={4}
          />
          <MSTableSection
            ref={age_ref}
            title="4.8 – POPULATION : FOCUS - SENIORS (+ DE 65 ANS)"
            surveyId={survey_id}
            data={populationSeniorDataPart4}
            theme={GRAY_THEME}
            switchModeMapData={{
              labels: [
                'Part des 65 ans et plus dans la population en 2021 (Insee 2024)',
                'Variation 2015-2021',
              ],
              name: SENIOR,
              currentMap: msMetaData ? msMetaData[SENIOR] : 'first',
              namesMap: [SENIOR_NB_MAP, SENIOR_VARIATION_MAP],
              onChangeMsDataMap: handleChangeMsDataMap,
            }}
            commentFieldName={SENIOR}
            part={4}
          />
          <BannerSection
            title={'5 - Sources et définitions'}
            surveyID={survey_id}
            theme={DARK_BLUE_THEME}
          />
          <MarketSurveySummary
            refsArray={summary}
            isPart5={true}
            ref={resumeRef_5}
            theme={DARK_BLUE_THEME}
          />
          <MSTableSection
            ref={definitionsRef}
            title={'Sources et définitions'}
            surveyId={survey_id}
            data={definition}
            theme={DARK_BLUE_THEME}
            isSourceAndDefBlock
            commentFieldName={DEFINITION}
            part={5}
            noNeedComment
          />
        </div>
      </div>
      <div className="block lg:hidden">
        <MarketSurveyMobile surveyId={survey_id} />
      </div>
    </>
  );
};

export default memo(MarketSurveyPage);
