import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { useMaxHeight } from '../../../hooks/useMaxHeight';
import SingleInputTable from '../../molecules/DataVisualisations/Tables/SingleInputTable';
import _ from 'lodash';
import CommentMarketSurvey from './CommentMarketSurvey';
import DualInputTable from '../../molecules/DataVisualisations/Tables/DualInputTable';
import {
  BUBBLE_CHART,
  CONTENT,
  DEFAULT_DATATYPE,
  DEFINITION_BLOCK,
  DUAL_INPUT_TABLE,
  EDUCATION_SECTION,
  LINE_CHART,
  LIST_BOXES,
  MAP,
  MAP_PROGRAMME,
  MERGED_ROW_TABLE,
  MOBILITY,
  MS_SHOW_SELECTED_PROGRAM,
  NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT,
  NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT_POI,
  POI_MAP,
  SINGLE_INPUT_TABLE,
  TABLE_SWITCH,
  VERTICAL_BAR_CHART,
  WIP,
} from '../../../_helpers/constant';
import MsMapContainer from './MsMapContainer';
import MsTimeTravelSection from './MsTimeTravelSection';
import { useSelectedParcels } from '../../../hooks/useSelectedParcels';
import WIPSection from './WIPSection';
import MsVerticalBarChart from './MsVerticalBarChart';
import MsLineChart from './MsLineChart';
import MsContent from './MsContent';
import MsBoxesContainer from './MsBoxesContainer';
import MSBubbleChart from './MSBubbleChart';
import classNames from 'classnames';
import DefinitionSection from './DefinitionSection';
import MergedRowTable from '../../molecules/DataVisualisations/Tables/MergedRowTable';
import SwitchInput from '../../atoms/switch-input/SwitchInput';
import ReactLoading from 'react-loading';
import TableSwitchModule from './TableSwitchModule';
import MapDataContainer from '../../molecules/DataVisualisations/Map/MapDataContainer';
import { useSelector } from 'react-redux';
import { useEDMStore } from '../../../_helpers/useGetDataEdm';

export const Contents = memo(
  ({
    data,
    isPdfRenderMode,
    theme,
    fieldIds,
    needIsochrone,
    isSelectedProgramsMap,
    strippedTable,
    coloredTrOptions,
    separatedTr,
    eductionMap,
    infosDetailsSelectedProgrammes,
    minHeightContent,
  }) => {
    return (
      data &&
      data.map(
        ({
          type,
          url,
          title,
          items,
          section,
          boxes,
          personnalisedSpan,
          onlySelected,
          externalData,
          data,
          isNeufProgrammeLoc,
          hightLightColumn,
          defaultTileLayer,
          otherContent,
          hFull,
          noCluster,
          nameMap,
          isMobility,
        }) => {
          switch (type) {
            case SINGLE_INPUT_TABLE:
              return (
                <SingleInputTable
                  isPdfRenderMode={isPdfRenderMode}
                  key={_.uniqueId('ms_table__')}
                  dataUrl={url}
                  theme={theme}
                  stripped={strippedTable}
                  coloredTrOptions={coloredTrOptions}
                  separatedTr={separatedTr}
                  externalData={externalData}
                  hightLightColumn={hightLightColumn}
                />
              );
            case DUAL_INPUT_TABLE:
              return (
                <DualInputTable
                  isPdfRenderMode={isPdfRenderMode}
                  key={_.uniqueId('ms_table__')}
                  dataUrl={url}
                  data={data}
                  theme={theme}
                />
              );
            case MERGED_ROW_TABLE:
              return (
                <MergedRowTable
                  isPdfRenderMode={isPdfRenderMode}
                  key={_.uniqueId('ms_table__')}
                  data={infosDetailsSelectedProgrammes}
                  theme={theme}
                />
              );
            case EDUCATION_SECTION:
              return (
                <MsTimeTravelSection
                  key={_.uniqueId('ms_table__')}
                  data={data}
                />
              );
            case MOBILITY:
              return (
                <MsTimeTravelSection
                  key={_.uniqueId('ms_table__')}
                  data={data}
                />
              );
            case NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT:
              return (
                <MsTimeTravelSection
                  key={_.uniqueId('ms_table__')}
                  data={data}
                />
              );
            case VERTICAL_BAR_CHART:
              return (
                <MsVerticalBarChart
                  key={_.uniqueId('ms_vertical_chart--')}
                  dataURL={url}
                  title={title}
                  data={data}
                />
              );
            case BUBBLE_CHART:
              return (
                <div
                  key={_.uniqueId('ms_vertical_chart--')}
                  className={classNames('w-full h-auto', personnalisedSpan)}
                >
                  <MSBubbleChart
                    dataURL={url}
                    title={title}
                    isPdfMode={isPdfRenderMode}
                    data={data}
                  />
                </div>
              );
            case LINE_CHART:
              return (
                <MsLineChart
                  key={_.uniqueId('ms_vertical_chart--')}
                  dataURL={url}
                  title={title}
                  data={data}
                />
              );
            case MS_SHOW_SELECTED_PROGRAM:
              return (
                <MsMapContainer
                  key={MS_SHOW_SELECTED_PROGRAM}
                  isPdfRenderMode={isPdfRenderMode}
                  dataType={MS_SHOW_SELECTED_PROGRAM}
                  isScrollWheelZoom={false}
                  url={url}
                  isSelectedProgramsMap={isSelectedProgramsMap}
                  minHeightContent={minHeightContent}
                />
              );
            case MAP_PROGRAMME:
              return (
                <MapDataContainer
                  key={MAP_PROGRAMME}
                  defaultTileLayer={defaultTileLayer}
                  onlySelected={onlySelected}
                  isPdfRenderMode={isPdfRenderMode}
                  isScrollWheelZoom={false}
                  dataType={MAP_PROGRAMME}
                  programmesNeufs={!onlySelected}
                  isSelectedProgramsMap={!onlySelected}
                  needIsochroneVoiture={!onlySelected}
                  isPdfMode={isPdfRenderMode && onlySelected}
                  minHeight={minHeightContent}
                  hFull={hFull}
                  noCluster={noCluster}
                  name={nameMap}
                />
              );
            case MAP:
              return (
                <MsMapContainer
                  nameMap={nameMap}
                  defaultTileLayer={defaultTileLayer}
                  isPdfRenderMode={isPdfRenderMode}
                  key={_.uniqueId('ms_table__')}
                  title={title}
                  url={url}
                  dataType={DEFAULT_DATATYPE}
                  needIsochrone={needIsochrone}
                  parcelId={fieldIds[0]}
                  eductionMap={eductionMap}
                  data={data}
                  minHeightContent={minHeightContent}
                  isMobility={isMobility}
                />
              );
            case POI_MAP:
              return (
                <MsMapContainer
                  nameMap={nameMap}
                  defaultTileLayer={defaultTileLayer}
                  key={_.uniqueId('ms_table__')}
                  title={title}
                  url={url}
                  dataType={POI_MAP}
                  needIsochrone={needIsochrone}
                  parcelId={fieldIds[0]}
                  minHeightContent={minHeightContent}
                />
              );
            case TABLE_SWITCH:
              return (
                <div
                  key={TABLE_SWITCH}
                  className={classNames('h-auto w-full', personnalisedSpan)}
                >
                  <TableSwitchModule
                    section={section}
                    parcelId={fieldIds[0]}
                    theme={theme}
                    isPdfRenderMode={isPdfRenderMode}
                  />
                </div>
              );
            case NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT_POI:
              return (
                <MsMapContainer
                  nameMap={nameMap}
                  defaultTileLayer={defaultTileLayer}
                  key={_.uniqueId('ms_map_nearest_shops__')}
                  title={title}
                  url={url}
                  dataType={NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT_POI}
                  data={data}
                  items={items}
                  needIsochrone
                  parcelId={fieldIds[0]}
                  minHeightContent={minHeightContent}
                />
              );
            case CONTENT:
              return (
                <MsContent
                  key={_.uniqueId('ms_table__')}
                  isPdfRenderMode={isPdfRenderMode}
                  title={title}
                  url={url}
                  data={data}
                  isNeufProgrammeLoc={isNeufProgrammeLoc}
                  otherContent={otherContent}
                />
              );
            case LIST_BOXES:
              return (
                <MsBoxesContainer
                  isPdfRenderMode={isPdfRenderMode}
                  key={_.uniqueId('ms_table__')}
                  boxes={boxes}
                />
              );
            case DEFINITION_BLOCK:
              return (
                <DefinitionSection
                  isPdfRenderMode={isPdfRenderMode}
                  key={_.uniqueId('ms_table__')}
                  data={data}
                  title={title}
                />
              );
            case WIP:
              return (
                <WIPSection key={_.uniqueId('ms_table__')} title={title} />
              );
          }
        }
      )
    );
  }
);

const SwitchModeContents = memo(
  ({
    switchModeData: {
      labels,
      currentMap = 'first',
      name,
      onChangeMsDataMap,
      namesMap,
    },
    data,
    isPdfRenderMode,
    theme,
    minHeight = 400,
  }) => {
    const [switchInputValue, setSwitchInputValue] = useState(false);
    const [loading, setLoading] = useState(false);
    const { fieldIds } = useSelectedParcels();
    const [isReadyTable, setIsReadyTable] = useState(false);
    const switchContainerRef = useRef(null);
    const mapCiontainerRef = useRef(null);
    const tableContainerRef = useRef(null);
    const [minHeightMap, setMinHeightMap] = useState(0);

    const handleOnReadyTable = () => {
      setTimeout(() => {
        setIsReadyTable(true);
      }, 500);
    };

    useEffect(() => {
      setSwitchInputValue(
        !currentMap ? false : currentMap === 'first' ? false : true
      );
    }, [currentMap]);

    const handleChangeSwitchInput = (value) => {
      setSwitchInputValue(value);
      onChangeMsDataMap && onChangeMsDataMap(value ? 'second' : 'first', name);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    useEffect(() => {
      setTimeout(() => {
        if (
          switchContainerRef.current &&
          tableContainerRef.current &&
          isReadyTable
        ) {
          const tableContainerHeight = tableContainerRef.current.clientHeight;
          const defaultMinHeight =
            minHeight - switchContainerRef.current.clientHeight;
          setMinHeightMap(
            tableContainerHeight >= defaultMinHeight
              ? tableContainerHeight
              : defaultMinHeight
          );
        }
      }, 500);
    }, [minHeight, isReadyTable]);

    return (
      <div
        style={{ minHeight: minHeight }}
        className={classNames('w-full flex flex-col', {
          'h-full': isPdfRenderMode,
        })}
      >
        {!isPdfRenderMode && (
          <div
            ref={switchContainerRef}
            className="flex items-center gap-x-2 py-2 lg:py-3 2xl:py-4"
            id="switch-map"
          >
            <span
              onClick={() => handleChangeSwitchInput(false)}
              className={classNames(
                'font-semibold text-base 2xl:text-lg hover:underline cursor-pointer',
                {
                  'bg-blue-400 inline-block px-2 py-0 rounded-md':
                    !switchInputValue,
                }
              )}
            >
              {labels[0]}
            </span>
            <SwitchInput
              onChange={handleChangeSwitchInput}
              value={switchInputValue}
            />
            <span
              onClick={() => handleChangeSwitchInput(true)}
              className={classNames(
                'font-semibold text-base 2xl:text-lg hover:underline cursor-pointer',
                {
                  'bg-blue-400 inline-block px-2 py-0 rounded-md':
                    switchInputValue,
                }
              )}
            >
              {labels[1]}
            </span>
          </div>
        )}
        <div
          className={classNames('w-full flex items-stretch gap-x-5 flex-grow')}
        >
          <div className="w-1/2 h-full overflow-hidden flex flex-col">
            {isReadyTable && (
              <div
                ref={mapCiontainerRef}
                style={{ minHeight: minHeightMap }}
                className="relative w-full flex-grow"
              >
                <div
                  style={{ zIndex: loading ? 10 : -1 }}
                  className="absolute left-0 top-0 w-full h-full bg-gray-200 flex justify-center items-center"
                >
                  <ReactLoading
                    type="bubbles"
                    color="#4299E1"
                    width={200}
                    height={200}
                  />
                </div>
                <div
                  style={{ left: !switchInputValue ? 0 : '-100%' }}
                  className="absolute z-0 w-full h-full top-0"
                >
                  <MsMapContainer
                    isPdfRenderMode={isPdfRenderMode}
                    title={data[0]?.title || ''}
                    url={data[0]?.url || null}
                    dataType={DEFAULT_DATATYPE}
                    data={data[0]?.dataTable || null}
                    minHeightContent={minHeightMap}
                    hFull
                    nameMap={namesMap?.length === 2 ? namesMap[0] : null}
                  />
                </div>
                <div
                  style={{ left: switchInputValue ? 0 : '100%' }}
                  className="absolute z-0 w-full h-full top-0"
                >
                  <MsMapContainer
                    isPdfRenderMode={isPdfRenderMode}
                    title={data[1]?.title || ''}
                    url={data[1]?.url || null}
                    dataType={DEFAULT_DATATYPE}
                    data={data[1]?.dataTable || null}
                    minHeightContent={minHeightMap}
                    hFull
                    nameMap={namesMap?.length === 2 ? namesMap[1] : null}
                  />
                </div>
              </div>
            )}
          </div>
          <div ref={tableContainerRef} className="w-1/2 h-auto mt-6 lg:mt-0">
            <TableSwitchModule
              section={name}
              parcelId={fieldIds[0]}
              theme={theme}
              isPdfRenderMode={isPdfRenderMode}
              onReadyTable={handleOnReadyTable}
            />
          </div>
        </div>
      </div>
    );
  }
);

const MSTableSection = forwardRef(
  (
    {
      partName,
      noNeedComment = false,
      title: titleSection,
      surveyId,
      data,
      commentFieldName,
      theme,
      switchModeMapData,
      needIsochrone,
      personalizedContent,
      isSelectedProgramsMap,
      strippedTable,
      coloredTrOptions,
      separatedTr,
      eductionMap,
      infosDetailsSelectedProgrammes,
      personnalisedGrid,
      part,
      isSourceAndDefBlock,
    },
    ref
  ) => {
    const maxHeight = useMaxHeight();
    const titleRef = useRef(null);
    const { fieldIds } = useSelectedParcels();
    const contentRef = useRef(null);
    const commentaireRef = useRef(null);
    const { isPdfMode: isPdfRenderMode } = useSelector((state) => state.others);
    const msContentRef = useRef(null);
    const [minHeightContent, setMinHeightContent] = useState(0);
    const persoContentRef = useRef(null);
    const wrapperRef = useRef(null);
    const [bannerHeight, setBannerHeight] = useState(50);
    const { argsGetFunc, sections, currentSummary } = useEDMStore(
      (state) => state
    );
    const [isGotData, setIsGotData] = useState(false);

    useEffect(() => {
      if (argsGetFunc && sections?.length && !isGotData) {
        const content =
          sections.find((item) => item.name === partName || item.part === part)
            ?.content || [];
        if (content.length) {
          const contentItem = content.find(
            (item) => item.name === commentFieldName
          );
          contentItem &&
            contentItem.getMethod &&
            typeof contentItem.getMethod === 'function' &&
            contentItem.getMethod(argsGetFunc);

          setIsGotData(true);
        }
      }
    }, [argsGetFunc, sections]);

    useEffect(() => {
      const fixOffset = () => {
        const bannerHeight =
          document.querySelector('.first_section_banner')?.clientHeight || 0;
        setBannerHeight(bannerHeight);

        if (isPdfRenderMode) {
          if (
            wrapperRef.current &&
            commentaireRef.current &&
            titleRef.current
          ) {
            const heighContent =
              wrapperRef.current.clientHeight -
              commentaireRef.current.clientHeight -
              titleRef.current.clientHeight;
            setMinHeightContent(heighContent);
            if (persoContentRef.current) {
              persoContentRef.current.style.height = `${heighContent}px`;
            }
            if (msContentRef.current) {
              msContentRef.current.style.minHeight = `${heighContent}px`;
            }
          }
        } else {
          if (
            contentRef.current &&
            titleRef.current &&
            commentaireRef.current
          ) {
            const containerHeight =
              window.innerHeight -
              (maxHeight + bannerHeight) -
              commentaireRef.current.clientHeight;
            contentRef.current.style.minHeight = `${containerHeight}px`;
            const titleH2Height = titleRef.current.clientHeight;
            if (msContentRef.current) {
              msContentRef.current.style.minHeight = `${
                containerHeight - titleH2Height
              }px`;
            }
            if (persoContentRef.current) {
              persoContentRef.current.style.height = `${
                containerHeight - titleH2Height
              }px`;
            }
            setMinHeightContent(containerHeight - titleH2Height);
          }
        }
      };
      fixOffset();
      window.addEventListener('resize', fixOffset);

      return () => {
        window.removeEventListener('resize', fixOffset);
      };
    }, [isPdfRenderMode, maxHeight]);

    const partContent =
      partName && currentSummary.find((item) => item.name === partName);

    const contentItem = partContent?.content.find(
      (item) => item.name === commentFieldName
    );

    const title = titleSection || contentItem?.label?.toUpperCase();

    return (
      <div
        ref={(_ref) => {
          ref.current = _ref;
          wrapperRef.current = _ref;
        }}
        className={classNames('z-10 flex-col flex justify-between w-full', {
          'px-8': !isPdfRenderMode,
        })}
        style={
          isPdfRenderMode
            ? {
                height: 'calc(100% - 48px)',
                maxHeight: 'calc(100% - 48px)',
              }
            : {
                minHeight: `calc(100vh - ${maxHeight + bannerHeight}px)`,
              }
        }
      >
        <div ref={contentRef} className="w-full">
          {title ? (
            <div
              ref={titleRef}
              className={classNames(
                { 'py-3': isPdfRenderMode },
                { 'py-2 2xl:py-3': !isPdfRenderMode }
              )}
            >
              <h3
                className={classNames('font-semibold uppercase', {
                  'text-2xl': isPdfRenderMode,
                  'text-lg 2xl:text-2xl': !isPdfRenderMode,
                })}
              >
                {title}
              </h3>
            </div>
          ) : (
            <div ref={titleRef} className="w-full py-4"></div>
          )}
          {personalizedContent ? (
            <div ref={persoContentRef} style={{ minHeight: 400 }}>
              {personalizedContent}
            </div>
          ) : (
            <div
              ref={msContentRef}
              className={classNames('grid w-full gap-x-5 z-10', {
                [`grid-cols-${switchModeMapData ? 1 : data?.length || 1}`]:
                  !personnalisedGrid,
                [personnalisedGrid?.containerClass]: personnalisedGrid,
              })}
            >
              {switchModeMapData && data?.length === 2 ? (
                <SwitchModeContents
                  data={data}
                  isPdfRenderMode={isPdfRenderMode}
                  switchModeData={switchModeMapData}
                  theme={theme}
                  minHeight={minHeightContent}
                />
              ) : (
                <Contents
                  minHeightContent={minHeightContent}
                  infosDetailsSelectedProgrammes={
                    infosDetailsSelectedProgrammes
                  }
                  strippedTable={strippedTable}
                  data={data}
                  isPdfRenderMode={isPdfRenderMode}
                  theme={theme}
                  fieldIds={fieldIds}
                  needIsochrone={needIsochrone}
                  isSelectedProgramsMap={isSelectedProgramsMap}
                  coloredTrOptions={coloredTrOptions}
                  separatedTr={separatedTr}
                  eductionMap={eductionMap}
                />
              )}
            </div>
          )}
        </div>
        {isSourceAndDefBlock && (
          <div
            className={classNames({
              'pb-8 pt-24': !isPdfRenderMode,
              'py-6': isPdfRenderMode,
            })}
          >
            <p className="text-center font-semibold text-xl">
              Evalparcel, une solution Metaxu Immo SAS / CECIM & CAPEM
            </p>
          </div>
        )}
        {commentFieldName && !noNeedComment && (
          <div
            style={isPdfRenderMode ? { height: 128 } : {}}
            ref={commentaireRef}
            className={classNames({
              'pt-4': isPdfRenderMode,
              'py-4': !isPdfRenderMode,
            })}
          >
            <CommentMarketSurvey
              isPdfRenderMode={isPdfRenderMode}
              survey_id={surveyId}
              commentFieldName={commentFieldName}
            />
          </div>
        )}
      </div>
    );
  }
);

export default memo(MSTableSection);
