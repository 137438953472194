import React, { memo } from 'react';
import useSWR from 'swr';
import _, { uniqueId } from 'lodash';
import classNames from 'classnames';
import {
  BLUE_THEME,
  DARK_BLUE_THEME,
  GREEN_THEME,
} from '../../../../_helpers/constant';
import { getFetcher } from '../../../../api/fetcher';
import LoadingSection from '../../../MarketSurveys/sections/LoadingSection';
import SectionTitle from '../../../MarketSurveys/sections/SectionTitle';
// import LegendSection from '../../../MarketSurveys/sections/LegendSection';

const checkToHighlight = (rowItems) =>
  !!rowItems
    .map((item) => item.value)
    .filter(
      (item) =>
        item?.toString().toLowerCase().trim().indexOf('total') >= 0 ||
        item?.toString().toLowerCase().trim().indexOf('moyenne') >= 0 ||
        item?.toString().toLowerCase().trim().indexOf('programmes') >= 0
    ).length;

const SingleInputTable = ({
  isPdfRenderMode,
  dataUrl = null,
  theme,
  // legendDataURL,
  stripped = true,
  coloredTrOptions,
  separatedTr,
  externalData = null,
  hightLightColumn,
}) => {
  const res = dataUrl && useSWR(dataUrl, getFetcher);

  const {
    name,
    date = '',
    headers,
    body,
  } = externalData ? externalData : res?.data || {};

  const orderedHeader = _.orderBy(headers, ['header', 'order']);

  const columnToHightlight =
    (
      hightLightColumn &&
      orderedHeader.map((item, index) => {
        if (item?.name?.toLowerCase().indexOf(hightLightColumn) >= 0) {
          return index;
        }
        return null;
      })
    )?.filter((item) => item) || [];

  if (!body) return <LoadingSection />;

  return (
    <>
      <div className={'flex flex-col flex-grow'}>
        {name && <SectionTitle title={name} />}
        {date && <h4>{date}</h4>}
        <div className="overflow-x-auto w-full">
          <table className="w-full">
            <thead className={'rounded-lg'}>
              <tr>
                {orderedHeader.map((header, index) => {
                  return (
                    <th
                      className={classNames(
                        {
                          'text-yellow-100 bg-green-700 ':
                            theme === GREEN_THEME,
                          'text-blue-100 bg-blue-500 ': theme === BLUE_THEME,
                          'text-blue-100 bg-blue-800 ':
                            theme === DARK_BLUE_THEME,
                        },
                        {
                          'px-1 text-xs 2xl:text-sm': !isPdfRenderMode,
                        },
                        { 'text-sm': isPdfRenderMode },
                        'first-letter:uppercase th-table text-center font-semibold'
                      )}
                      rowSpan={header.rowSpan}
                      colSpan={header.colSpan}
                      key={uniqueId(`${header.name}---${index}`)}
                    >
                      <div
                        className="th-content"
                        dangerouslySetInnerHTML={{ __html: header.name }}
                      ></div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {body &&
                body.map((rowItems, index) => {
                  const highlightTr = checkToHighlight(rowItems);

                  const firstValue =
                    rowItems[0] && rowItems[0].value.trim().toLowerCase();

                  const bgColorTr =
                    firstValue &&
                    coloredTrOptions?.find(
                      (item) => firstValue.indexOf(item.keyword) >= 0
                    );

                  const boldBorderTr =
                    separatedTr && (index + 1) % separatedTr === 0;

                  return (
                    <tr
                      key={uniqueId(`row__${index}`)}
                      className={classNames(
                        {
                          'font-bold bg-opacity-75': highlightTr,
                          'bg-green-600': theme === GREEN_THEME && highlightTr,
                          'bg-blue-500': theme === BLUE_THEME && highlightTr,
                          'bg-blue-800':
                            theme === DARK_BLUE_THEME && highlightTr,
                          'bg-gray-300':
                            stripped &&
                            index % 2 !== 0 &&
                            !highlightTr &&
                            !coloredTrOptions,
                          'border-b-1 border-gray-500': !boldBorderTr,
                          'border-b-4 border-black': boldBorderTr,
                        },
                        { [bgColorTr?.bgColorClass]: bgColorTr }
                      )}
                    >
                      {rowItems.map((item, index) => {
                        return (
                          <td
                            key={`item__${item.value}__${index}`}
                            className={classNames(
                              'font-medium',
                              {
                                'text-base leading-none py-2.5 px-1':
                                  isPdfRenderMode,
                                'bg-green-700 bg-opacity-25':
                                  columnToHightlight?.includes(index) &&
                                  !highlightTr,
                                'text-xs leading-none py-2 px-1':
                                  isPdfRenderMode,
                                'px-2 py-2 text-xs 2xl:text-sm':
                                  !isPdfRenderMode,
                                'text-center': index !== 0,
                              },
                              'whitespace-nowrap text-gray-900 text-center'
                            )}
                            colSpan={item.colSpan}
                            dangerouslySetInnerHTML={{
                              __html: item.value || '-',
                            }}
                          />
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* {legendDataURL && <LegendSection dataURL={legendDataURL} />} */}
      </div>
    </>
  );
};

export default memo(SingleInputTable);
